<template>
	<div :class="['avatar', {'is-active': active}]">
		<div class="avatar-inner">
			<div class="avatar-logo">
				<Logo aria-label="Hello" />
			</div>

			<div class="avatar-image">
				<picture>
					<source
						srcset="
							@/assets/avatar@140w.jpg 1x,
							@/assets/avatar@280w.jpg 2x,
							@/assets/avatar@560w.jpg 3x
						"
						media="(max-width: 320px)"
					>
					<source
						srcset="
							@/assets/avatar@168w.jpg 1x,
							@/assets/avatar@336w.jpg 2x,
							@/assets/avatar@672w.jpg 3x
						"
						media="(max-width: 375px)"
					>
					<img
						src="@/assets/avatar.jpg"
						srcset="
							@/assets/avatar.jpg 1x,
							@/assets/avatar@2x.jpg 2x,
							@/assets/avatar@3x.jpg 3x
						"
						alt="Avatar"
						width="250"
						height="250"
						@load="_onLoad"
					>
				</picture>
			</div>
		</div>

		<h1 class="avatar-title">
			Sascha Künstler
		</h1>

		<div class="avatar-text">
			<p>
				13086 Berlin, D<br>
				<a
					:href="`mailto:${email}`"
					:title="`E-Mail an ${email}`"
				>
					{{ email }}
				</a><br>
				+49 (0) 151 25327304
			</p>
		</div>
	</div>
</template>

<script>
import Logo from '@/assets/logo.svg?inline';

export default {
	components: {
		Logo
	},
	data() {
		return {
			active: false,
			email: 'contact.schascha@gmail.com'
		};
	},
	methods: {
		_onLoad() {
			this.active = true;
		}
	}
};
</script>

<style lang="scss" scoped>
	.avatar {
		margin: 0 auto;
		width: 100%;
		min-width: 250px;
		max-width: 500px;
		text-align: center;

		svg,
		img {
			width: auto;
			max-width: 100%;
			height: auto;
			vertical-align: bottom;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
		}

		&-inner {
			position: relative;
			margin: 25% 25% var(--space-10);
		}

		&-image {
			overflow: hidden;
			position: relative;
			z-index: 1;
			padding-top: 100%;
			border-radius: 50%;
		}

		&-logo {
			opacity: 0;
			position: absolute;
			top: -50%;
			right: -50%;
			width: 74%;
		}

		&-title {
			margin: 0;
		}

		&.is-active &-logo {
			animation: hello 2.5s ease-in-out;
			opacity: 1;

			@media screen and (prefers-reduced-motion: reduce) {
				animation: none;
			}
		}

		&.is-active svg {
			animation: shake 10s ease-in-out infinite 5s;

			@media screen and (prefers-reduced-motion: reduce) {
				animation: none;
			}
		}
	}

	@keyframes hello {
		0% {
			transform: rotate(-40deg) translate(-120%, -15%);
		}

		50% {
			transform: rotate(8deg) translate(30%, 15%);
		}
	}

	@keyframes shake {
		1.5% {
			transform: translate(8px, 8px);
		}

		3% {
			transform: translate(-6px, -6px);
		}

		5% {
			transform: translate(4px, 4px);
		}

		6.5% {
			transform: translate(-2px, -2px);
		}

		8% {
			transform: translate(1px, 1px);
		}

		10% {
			transform: translate(0, 0);
		}
	}
</style>
